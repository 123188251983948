import React from 'react'
import {Link} from 'gatsby'

import './page4.css'


export default class page4 extends React.Component {

    

    componentDidMount() {

      
        var d=document; var x=!d.getElementById('razorpay-embed-btn-js')
        if(x){ var s=d.createElement('script'); s.defer=!0;s.id='razorpay-embed-btn-js';
        s.src='https://cdn.razorpay.com/static/embed_btn/bundle.js';d.body.appendChild(s);} else{var rzp=window['__rzp__'];
        rzp && rzp.init && rzp.init()}


      // const form  = document.createElement("form");
      // const form_int  = document.createElement("form");
      
        // const script = document.createElement("script");
        // script.async = false;
        //  script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        // script.setAttribute("data-payment_button_id","pl_GKSydPNqFcJWrs");
        // form.appendChild(script);
        // document.getElementById('payment_button').appendChild(form);
        
        
        // const script_int = document.createElement("script");
        // script_int.async = false;
        // script_int.src = "https://checkout.razorpay.com/v1/payment-button.js";
        // script_int.setAttribute("data-payment_button_id","pl_HEg8OuQNiaIuo8");
        // form_int.appendChild(script_int);
        // document.getElementById('payment_button_int').appendChild(form);

       
    }


  render() {
    return (


      <div className="page4-main">
        
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.2/css/all.min.css" />
          <div>
          <div id="back-index" className="back-player">
            <Link div="prev-button" to ="/">
       
            <i style={{color:'#3287a7',paddingTop:'20px',paddingLeft:'20px',fontSize:'20px'}}  class="fas fa-chevron-left"></i>
            </Link>
          
          </div>
          </div>
          {/* <center>
            <div id="payment_details">
              <div  id="payment_details_2"><div>Legacy Pre Order</div><div style={{display:'flex',justifyItems:'flex-end',justifyContent: 'flex-end'}}> 1500</div><div></div> </div>
              
              <div  id="payment_button"> */}
          {/* <from id="form"></from> */}
          {/* </div>
            </div>
          
          </center> */}
          
          <center> 
            <div style={{marginTop:'12vh',backgroundColor:'white',width:' fit-content',padding:'30px',borderRadius:'12px'}}>
            <div  id="payment_button">
            <div id = 'text'>For Indian Subscribers</div>
            <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_GKSydPNqFcJWrs" async> </script> </form>
          <div style={{paddingTop:'10px'}}>Total amount: ₹1800<br/>(includes ₹1500 + 18% GST + 2% transaction fee)</div>
          {/* <from id="form"></from> */}
          </div>
          <div style={{width:'10px',paddingBottom:'20px'}}></div>
          <hr/>
          <div style={{width:'10px'}}></div> 
          <div id ="payment_button_int">
          <div id='text'>For International Subscribers </div>
          <div style={{fontStyle:'italic'}}>* International subscribers get <br/>2 subscribers per subscription </div>
          <div class="razorpay-embed-btn" data-url="https://pages.razorpay.com/pl_HErP5kBS9VcvbN/view" data-text="Pay Now" data-color="#22304c" data-size="small">
          
  <script>
  </script>
  
</div><div>Total amount: $121 <br/> (includes $100 + 18% GST + 3% transaction fee)</div>
            {/* <from id="form_int"></from> */}
          </div>
          
            </div>
            
          </center>
      </div>
    )
  }
}

